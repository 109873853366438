import React from 'react'
import copyright from 'copyright'
import LinkList from 'components/LinkList'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import styles from './Footer.module.css'

const links = [
  {
    href: '/sitemap',
    label: 'Sitemap',
    isExternal: true
  },
  {
    href: '/terms',
    label: 'Terms',
    isExternal: true
  },
  {
    href: '/privacy',
    label: 'Privacy',
    isExternal: true
  },
  {
    href: '/contact',
    label: 'Contact'
  },
  {
    href: String(process.env.NEXT_PUBLIC_HELP_URL),
    label: 'Help',
    isExternal: true
  }
]

const Footer = () => (
  <footer className={styles.footer}>
    <Container className={styles.container}>
      <section className={styles.section}>
        <small>{copyright({ name: '��������', short: true })}</small>
      </section>

      <section className={styles.section}>
        <LinkList className={styles.legalLinklist} links={links} />
      </section>
    </Container>
  </footer>
)

export default Footer
